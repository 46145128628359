.sponsors-container{
    border-radius: 5px;
    background: rgba(14, 36, 84, 0.36);
    text-align: center;
    width: 80%;
    margin-top: 120px;
    
    margin-left: auto;
    margin-right: auto;
    
}

.sponsor-title{
    color: #ffffff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ui.orange.button{
    font-size: 20px;
    margin-bottom: 20px;
}

.c4c-subtitle{ 
    color: white;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


